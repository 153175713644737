import React, { useRef, useEffect, useState, useCallback } from 'react';
import Webcam from 'react-webcam';
import * as THREE from 'three';
import * as tf from '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-converter';
import '@tensorflow/tfjs-backend-webgl';
import * as faceLandmarksDetection from '@tensorflow-models/face-landmarks-detection';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import glassesSrc from './assets/S_Bubbles.glb'; // Update the path to your GLB model
import textsData from './assets/texts.json';

const colors = [
  0xff0000, // Red
  0x00ff00, // Green
  0x0000ff, // Blue
  0xffff00, // Yellow
  0xff00ff, // Magenta
  0x00ffff, // Cyan
  0xffa500, // Orange
  0x800080, // Purple
  0x008000, // Dark Green
  0x000080  // Navy
];

const VirtualTryOn = () => {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const [model, setModel] = useState(null);
  const [glassesTemplate, setGlassesTemplate] = useState(null);
  const [font, setFont] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [facesDetected, setFacesDetected] = useState(0);
  const [scene, setScene] = useState(null);
  const [camera, setCamera] = useState(null);
  const [renderer, setRenderer] = useState(null);
  const activeObjectsRef = useRef([]);
  const [modelsAdded, setModelsAdded] = useState(0);

  const loadResources = useCallback(async () => {
    try {
      // Camera Access
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (webcamRef.current) {
        webcamRef.current.srcObject = stream;
      }

      // TensorFlow Model
      await tf.setBackend('webgl');
      const loadedModel = await faceLandmarksDetection.load(
        faceLandmarksDetection.SupportedPackages.mediapipeFacemesh,
        { shouldLoadIrisModel: true, maxFaces: 5 }
      );
      setModel(loadedModel);

      // Three.js Setup
      const width = canvasRef.current.clientWidth;
      const height = canvasRef.current.clientHeight;
      const newScene = new THREE.Scene();
      const newCamera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
      newCamera.position.z = 5;
      const newRenderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, alpha: true });
      newRenderer.setSize(width, height);

      setScene(newScene);
      setCamera(newCamera);
      setRenderer(newRenderer);

      // Add lighting to the scene
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
      newScene.add(ambientLight);
      const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight.position.set(0, 1, 1).normalize();
      newScene.add(directionalLight);

      // Load Font
      const loadedFont = await new Promise((resolve, reject) => {
        new FontLoader().load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', resolve, undefined, reject);
      });
      setFont(loadedFont);

      // Load 3D Model
      const loadedGlasses = await new Promise((resolve, reject) => {
        new GLTFLoader().load(glassesSrc, (gltf) => resolve(gltf.scene), undefined, reject);
      });

      // Set up the loaded glasses
      loadedGlasses.traverse((child) => {
        if (child.isMesh) {
          child.material = new THREE.MeshStandardMaterial({
            color: child.material.color,
            metalness: child.material.metalness || 0.5,
            roughness: child.material.roughness || 0.5,
            map: child.material.map || null,
          });
        }
      });

      // Increase initial scale
      loadedGlasses.scale.set(1, 1, 1);
      loadedGlasses.rotation.set(181, Math.PI, 0);
      setGlassesTemplate(loadedGlasses);

      // Clear the initial scene
      newRenderer.clear();
      newRenderer.render(newScene, newCamera);

      setIsLoading(false);
    } catch (error) {
      console.error("Initialization error:", error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadResources();
  }, [loadResources]);

  const createTextGroup = useCallback((text) => {
    const textMaterial = new THREE.MeshPhongMaterial({ color: 0xffffff });
    const lines = text.split('*');
    const group = new THREE.Group();

    let totalHeight = 0;
    lines.forEach((line) => {
      const textGeometry = new TextGeometry(line, {
        font: font,
        size: 0.3,
        height: 0.05,
        curveSegments: 12,
        bevelEnabled: false,
      });
      const textMesh = new THREE.Mesh(textGeometry, textMaterial);
      textMesh.geometry.computeBoundingBox();
      const textWidth = textMesh.geometry.boundingBox.max.x - textMesh.geometry.boundingBox.min.x;
      const textHeight = textMesh.geometry.boundingBox.max.y - textMesh.geometry.boundingBox.min.y;

      textMesh.position.set(-textWidth / 2, -totalHeight, 1.3);
      totalHeight += textHeight * 1.3;

      group.add(textMesh);
    });

    group.position.y = totalHeight / 2;
    return group;
  }, [font]);

  const detectAndPositionObjects = useCallback(async () => {
    if (!webcamRef.current || !model || !font || !glassesTemplate || !scene || !camera || !renderer) return;
    const video = webcamRef.current.video;
    if (video.readyState !== 4) return;

    const faceEstimates = await model.estimateFaces({ input: video });
    setFacesDetected(faceEstimates.length);

    const addedModels = faceEstimates.length;
    const removedModels = activeObjectsRef.current.length - addedModels;

    // Remove excess objects if the number of detected faces is less than currently added models
    while (activeObjectsRef.current.length > faceEstimates.length) {
      const removed = activeObjectsRef.current.pop();
      scene.remove(removed.glasses);
      scene.remove(removed.text);
    }

    // Add new objects only if faces are detected
    while (activeObjectsRef.current.length < faceEstimates.length) {
      const index = activeObjectsRef.current.length;
      const glassesMesh = glassesTemplate.clone();
      const color = colors[index % colors.length];

      glassesMesh.traverse((child) => {
        if (child.isMesh) {
          child.material = new THREE.MeshStandardMaterial({
           // color: color,
            metalness: 0.5,
            roughness: 0.5,
          });
        }
      });

      const textGroup = createTextGroup(window.textsData1.texts[Math.floor(Math.random() * window.textsData1.texts.length)]);
      scene.add(glassesMesh);
      scene.add(textGroup);
      activeObjectsRef.current.push({ glasses: glassesMesh, text: textGroup });
    }

    // Update the total count of models added
    setModelsAdded(activeObjectsRef.current.length);

    // Update positions for existing glasses and text
    faceEstimates.forEach((faceEstimate, index) => {
      const keypoints = faceEstimate.scaledMesh;
      const forehead = keypoints[10];
      const scaleX = -0.01;
      const scaleY = -0.01;
      let offsetX = 4;
      let offsetY = 2;
      const { glasses, text } = activeObjectsRef.current[index];
      let mp = (forehead[0] - video.videoWidth / 2) * scaleX + offsetX;
      if (mp.toFixed(0) > -1) {
        offsetX = 2;
      }

      // Adjust positioning for larger glasses
      glasses.position.set(
        (forehead[0] - video.videoWidth / 2) * -0.01 - offsetX,
        (forehead[1] - video.videoHeight / 2) * -0.01 + offsetY,
        -0.5  // Moved slightly closer to the camera
      );

      const scaleMultiplier = Math.sqrt(Math.pow(keypoints[359][0] - keypoints[130][0], 2) + Math.pow(keypoints[359][1] - keypoints[130][1], 2)) / 300;
      const scaleFactor = 100;
glasses.scale.set(scaleMultiplier * scaleFactor, scaleMultiplier * scaleFactor, scaleMultiplier * scaleFactor);
      const textScaleMultiplier = Math.max(0.5, Math.min(1, scaleMultiplier * 2));

      text.scale.set(textScaleMultiplier, textScaleMultiplier, textScaleMultiplier);

      const textOffsetY = -2.5 * scaleMultiplier; // Adjusted for larger glasses
      text.position.set(glasses.position.x + 0.4, glasses.position.y + textOffsetY, glasses.position.z + 0.5);
    });

    renderer.render(scene, camera);
  }, [model, glassesTemplate, font, scene, camera, renderer, createTextGroup]);

  useEffect(() => {
    if (!isLoading) {
      const intervalId = setInterval(() => {
        detectAndPositionObjects();
      }, 120);

      return () => clearInterval(intervalId);
    }
  }, [isLoading, detectAndPositionObjects]);

  return (
    <>
      <div className="header">
        <h1>Bubble</h1>
      </div>
      <div className="container">
        {isLoading && (
          <div className="loading">
            <h3>Loading...</h3>
          </div>
        )}
        <Webcam ref={webcamRef} autoPlay playsInline className="webcam" mirrored={true} />
        <canvas ref={canvasRef} className="canvas" />
       
      </div>
    </>
  );
};

export default VirtualTryOn;
/***
 *  <div className="facemetric">
          <div className="face-count">Faces detected: {facesDetected}</div>
          <div className="model-count">3D models added: {modelsAdded}</div>
        </div>
 */